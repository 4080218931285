body {
  font-family: "Afacad Flux", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  font-variation-settings:
          "slnt" 0;
}

.required:after {
  content: " *";
  /*color: #840C0C;*/
  color: black;
}

.camp-burgundy {
  /*color: #840C0C;*/
  color: black;
}

.camp-burgundy-light {
  /*color: #BB1313;*/
  color: black;
}

.camp-btn-primary {
  background-color: #A60000;
  color: white;
}

.camp-btn-primary:hover {
  background-color: #A60000E2;
  color: white;
}

.camp-fw300 {
  font-weight: 300;
}

.camp-fw400 {
  font-weight: 400;
}

.camp-fw500 {
  font-weight: 500;
}

.camp-fw600 {
  font-weight: 600;
}

.camp-fw700 {
  font-weight: 700;
}

.camp-spacer-10px-left {
  margin-left: 10px !important;
}

.camp-spacer-20px-left {
  margin-left: 20px !important;
}

.form-label {
  font-size: 18px;
  font-weight: 700;
}

.form-control {
  font-size: 18px;
  font-weight: 500;
}

.form-select {
  font-size: 18px;
  font-weight: 500;
}

.error-text {
  display: none;
  color: red;
}

#submitDialog {
  border: none;
}

#dialogSpinner {
  width: 3rem;
  height: 3rem;
  /*color: #840c0c;*/
  color: black;
}

#dialogText {
  /*color: #840c0c;*/
  color: black;
  font-weight: 500;
}
