/* Add this to your CSS file */
.date-picker-wrapper .form-control {
    padding: 10px 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    outline: none;
    transition: border-color 0.3s ease;
}

.date-picker-wrapper .form-control:focus {
    border-color: #007bff;
}

.react-datepicker__input-container input {
    width: 100%;
}

.accordion-button:not(.collapsed) {
    color: white;
    background-color: #A60000;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #A60000 !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}